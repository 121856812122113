import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import HeadlineEnhanced from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/HeadlineEnhanced"

import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"

const DefaultPage = ({ content, breadcrumbs, language, name }) => {
  let seoImage = null

  if (
    content.body &&
    content.body[0] &&
    content.body[0].image &&
    content.body[0].image.filename
  ) {
    seoImage = content.body[0].image.filename
  }

  return (
    <Layout language={language}>
      <SEO
        title={content.headline}
        content={content}
        og_image={seoImage}
        language={language}
        name={name}
      />
      {content?.body?.length > 0 &&
        content.body[0].component !== "hero_block" &&
        content.body[0].component !== "hero_overlay_block" && (
          <HeroBlock
            block={{ headline: content.headline || name }}
            breadcrumbs={breadcrumbs}
          />
        )}
      <BodyBlocks body={content.body} breadcrumbs={breadcrumbs} />
    </Layout>
  )
}

export default DefaultPage
