import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Figure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Figure"

import * as moment from "moment"
import PressInfoBlock from "../../../../components/blocks/PressInfoBlock"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"

import BreadcrumbsMobileDropdown from "../../../../components/modules/BreadcrumbsMobileDropdown"

const isEmptyObj = (obj) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}

const NewsPage = ({ content, language, breadcrumbs, name }) => {
  let seoImage = null

  if (
    !content.image?.filename &&
    content.body &&
    content.body[0] &&
    content.body[0].image &&
    content.body[0].image.filename
  ) {
    seoImage = content.body[0].image.filename
  } else {
    seoImage = content.image?.filename
  }

  return (
    <Layout language={language}>
      <SEO
        title={content.headline}
        content={content}
        og_image={seoImage}
        language={language}
        name={name}
      />
      <BreadcrumbsMobileDropdown breadcrumbs={breadcrumbs} />
      <BlockWrapper block={{}} blockWidth="container" blockPadding="pt-8">
        <div>
          <span className="inline-block w-3 h-3 align-baseline bg-ro-red"></span>
          {content.display_date?.length > 0 ? (
            <span className="ml-4">
              {moment(content.display_date).format("DD.MM.YYYY")}
            </span>
          ) : (
            ""
          )}
          {content.kicker?.length > 0 &&
            content.categories &&
            content.categories[0] &&
            !isEmptyObj(content.categories[0]) && (
              <span className="ml-4 font-bold">{content.categories[0]}</span>
            )}
        </div>
        <h1 className="mt-4 headline headline-h2">{content.title}</h1>
        <div className="mt-4 text-lg leading-loose text-ro-gray-500 lg:leading-loose lg:text-xl">
          {content.teaser}
        </div>
      </BlockWrapper>
      {content.image?.filename?.length > 0 && (
        <BlockWrapper
          block={{}}
          blockPadding=""
          blockWidth="container"
          className=""
        >
          <Figure
            image_width="container"
            image={content.image}
            caption={content.image?.name}
            className="pt-8"
          />
        </BlockWrapper>
      )}
      {content.body?.length > 0 && <div className="-mb-8" />}
      <BodyBlocks body={content.body} />
      {language === "de" && (
        <PressInfoBlock>
          <span className="mr-2 font-bold">Pressekontakt:</span>
          ROTHENBERGER AG, Spessartstraße 2-4, 65779 Kelkheim, E-Mail:{" "}
          <Link
            className="text-ro-red hover:text-ro-red-700 focus:text-ro-red-700"
            link="presse@rothenberger.com"
          >
            presse(at)rothenberger.com
          </Link>
        </PressInfoBlock>
      )}
    </Layout>
  )
}

export default NewsPage
